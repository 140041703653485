<template>
  <div v-if="meetDialogInfo">
    <el-dialog
      custom-class="commonElDialog"
      :width="`${meetDialogInfo.width || 380}px`"
      :show-close="meetDialogInfo.showClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      :visible="!!meetDialogInfo"
      @close="closeHandle"
    >
      <div class="dialog-title">
        <div class="icon error"></div>
        <div class="text">
          {{ meetDialogInfo.tips || $t('login.tips') }}
        </div>
      </div>

      <div class="dialog-content">{{ meetDialogInfo.describe }}</div>
      <div class="dialog-content">{{ meetDialogInfo.commonText }}</div>

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="knowClick">
          {{ meetDialogInfo.ensureText || $t('login.know')}}
        </el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { removeUserInfo,getMopanLinks } from "@/utils/auth"

export default {
  props: {
    meetDialogInfo: {
      type: Object,
      default: null
    }
  },
  data() {
    return {
    }
  },
  methods: {
    closeHandle() {
      this.$emit('close')
    },
    knowClick() {
      const { isGoLogin, isGoIndex } = this.meetDialogInfo
      this.$emit('close')
      if (isGoLogin) { // 跳转到登录页
        removeUserInfo()
        this.$router.push({
          name: 'join'
        })
      // window.location.href = getMopanLinks();
      } else if (isGoIndex) { // 跳转到首页
        this.$router.push({
          name: 'join'
        })
      // window.location.href = getMopanLinks();
      }
    }
  },
}
</script>

<style lang="scss">
.el-dialog.commonElDialog {
  background: #FFFFFF;
  box-shadow: 0px 0px 20px 0px rgba(0, 0, 0, 0.2);
  border-radius: 8px;
  border: 1px solid rgba(0, 0, 0, 0.1);

  .el-dialog__body {
    padding-top: 0;
  }

  .dialog-title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    .icon {
      width: 24px;
      height: 24px;
      margin-right: 10px;

      &.success {
        background: url(~@/assets/images/icon-success.png) no-repeat;
        background-size: 100% 100%;
      }

      &.error {
        background: url(~@/assets/images/icon-error.png) no-repeat;
        background-size: 100% 100%;
      }
    }

    .text {
      font-size: 20px;
      font-weight: 500;
      color: #333333;
      line-height: 28px;
      width: calc(100% - 34px);
      word-break: break-word;
    }
  }

  .dialog-content {
    font-size: 14px;
    font-weight: 400;
    color: #666666;
    line-height: 21px;

    .content-item {
      display: flex;
      margin-bottom: 10px;

      .left {
        width: 100px;
        // background-color: red;
      }

      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
