
import { DEFAULT_VOLUME, DEVICE_AUTH_SUCCESS, DEVICE_AUTH_FAIL } from "@/constant/index";

import { loganLog } from "@/utils/log"

import {
   getDeviceAuth,
   setDeviceAuth,
   checkDevicePermission
} from "@/utils/device"

function DeviceManager(vueInstance) {
   this.vue = vueInstance
   this.audioContext = null
   this.soundMeter = null
   this.micTestVolume  = 0
   this.micTestVolumeQueryInterval = null
   this.micStream = null
   this.stopCameraInterval = null
}

//初始化
DeviceManager.prototype.init = async function() {
   //设备初始化
   // this.deviceRoomClient = new RoomClient({
   //    imEnv
   // }).i100Settings

   this.deviceRoomClient = this.vue.$i100MeetSDK.settings
   this.undataDeviceList()
   //设备热插拔
   this.devicechangeHandle()
}

// 更新设备列表
DeviceManager.prototype.undataDeviceList = async function() {
   //1.6改用底层SDK提供的方法，以前是在上层直接调用的navigator.mediaDevices
   //会前使用this.roomClient，后期底层SDK优化模块化实例
   //会中使用this.vue.$roomClien
   const micDeviceList =  await  this.deviceRoomClient.getMicDevicesList()
   micDeviceList.forEach(item => {
      item.deviceName = item.deviceName.replace(/立体声/g,"(不可用)立体声")
   })
   // loganLog(`麦克风设备列表 DeviceList:${JSON.stringify(micDeviceList)}`);

   this.vue.$store.commit('device/setMicDeviceList', micDeviceList)
   await this.setCurrentMicDevice(null, false)
   this.initMicDeviceVolume(false)

   const speakerDeviceList = await  this.deviceRoomClient.getSpeakerDevicesList()
   // loganLog(`扬声器设备列表 DeviceList:${JSON.stringify(speakerDeviceList)}`);

   this.vue.$store.commit('device/setSpeakerDeviceList', speakerDeviceList)
   this.setCurrentSpeakerDevice()
   this.initPlayoutVolume(null, false)

   const camDeviceList = await  this.deviceRoomClient.getCameraDevicesList()
   // loganLog(`摄像头设备列表 DeviceList:${JSON.stringify(camDeviceList)}`);

   this.vue.$store.commit('device/setCameraDeviceList', camDeviceList)

   await this.setCurrentCameraDevice(null, false)
}
//设备热插拔处理
DeviceManager.prototype.devicechangeHandle = async function() {
   // --------------------------------设备热插拔处理 ------------------------
   const deviceChangeEvent = async function() {
      //异步先行
      const localAudioDeviceId = await this.vue.$i100MeetSDK.i100Settings.getConfig('audioDeviceId')
      const localSpeakerDeviceId = await this.vue.$i100MeetSDK.i100Settings.getConfig('speakerDeviceId')
      let _ismeeting = window.location.pathname.match(/meeting/)

      
      const newMicDeviceList = await this.deviceRoomClient.getMicDevicesList()
      newMicDeviceList.forEach(item => {
         item.deviceName = item.deviceName.replace(/立体声/g,"(不可用)立体声")
      })
      
      const newSpeakerDeviceList = await this.deviceRoomClient.getSpeakerDevicesList()
      const newCamDeviceList = await this.deviceRoomClient.getCameraDevicesList()

      const {
         micDeviceList,
         speakerDeviceList,
         cameraDeviceList
      } = this.vue.$store.state.device
      //麦克风
      if (JSON.stringify(micDeviceList) !== JSON.stringify(newMicDeviceList)) {
         this.vue.$store.commit('device/setMicDeviceList', newMicDeviceList)
         this.vue.$store.commit("meet/updateGlobalMeetState", {
            "micNoGrant":null
         })

         loganLog(`麦克风 deviceChange 执行了`)
         console.error(micDeviceList)
         console.error(newMicDeviceList)
         let deviceId = '',deviceName

         if(newMicDeviceList.length === 1){
            deviceId = newMicDeviceList[0].deviceId 
            deviceName = newMicDeviceList[0].deviceName 
         }else if(micDeviceList.length && newMicDeviceList.length && newMicDeviceList.length > micDeviceList.length){
            const obj = {}
            //将新插入的设备切换为当前设备
            micDeviceList.forEach(item => obj[`${item.deviceId}`] = true) 
            newMicDeviceList.forEach(item => {
               if(!obj[item.deviceId]){ 
                  deviceId = item.deviceId 
                  deviceName = item.deviceName 
               }
            })
         } else{
            newMicDeviceList.forEach(item => {
               if(item.isDefault){
                  deviceId = item.deviceId 
                  deviceName = item.deviceName 
               }
            })
         }
        
         if(deviceId){
            if(localAudioDeviceId === deviceId){
               console.error("当前麦克风设备ID没变")
               await this.setCurrentMicDevice()
            }else{
               loganLog(`麦克风变更为${deviceName}`)
               _ismeeting && _ismeeting.index && this.vue.$totast(`您的麦克风已更换为${deviceName}`)
               await this.setCurrentMicDevice(deviceId,true)
            }
         }else{
            await this.setCurrentMicDevice()
         }
      }

      //扬声器
      if (JSON.stringify(speakerDeviceList) !== JSON.stringify(newSpeakerDeviceList)) {
         this.vue.$store.commit('device/setSpeakerDeviceList', newSpeakerDeviceList)
         loganLog(`扬声器 deviceChange 执行了`)
         
         let deviceId = '',deviceName
         
         if(newSpeakerDeviceList.length === 1){
            deviceId = newSpeakerDeviceList[0].deviceId 
            deviceName = newSpeakerDeviceList[0].deviceName 
         }else if(speakerDeviceList.length && newSpeakerDeviceList.length && newSpeakerDeviceList.length > speakerDeviceList.length){
            const obj = {}
            //将新插入的设备切换为当前设备
            speakerDeviceList.forEach(item => obj[`${item.deviceId}`] = true) 
            newSpeakerDeviceList.forEach(item => {
               if(!obj[item.deviceId]){ 
                  deviceId = item.deviceId 
                  deviceName = item.deviceName 
               }
            })
         } else{
            newSpeakerDeviceList.forEach(item => {
               if(item.isDefault){
                  deviceId = item.deviceId,
                  deviceName = item.deviceName 
               }
            })
         }
        
         if(deviceId){
            if(localSpeakerDeviceId === deviceId){
               console.error("当前扬声器设备ID没变")
               this.setCurrentSpeakerDevice()
            }else{
               loganLog(`扬声器变更为${deviceName}`)
               _ismeeting && _ismeeting.index && this.vue.$totast(`您的扬声器已更换为${deviceName}`)
               this.setCurrentSpeakerDevice(deviceId)
            }
         }else{
            this.setCurrentSpeakerDevice()
         }
      }

      // console.error(12121)
      // console.error(cameraDeviceList)
      // console.error(newCamDeviceList)
      //摄像头
      if (JSON.stringify(cameraDeviceList) !== JSON.stringify(newCamDeviceList)) {
         this.vue.$store.commit('device/setCameraDeviceList', newCamDeviceList)
         loganLog(`摄像头 deviceChange 执行了`)
         console.error(cameraDeviceList,newCamDeviceList)
         let localDeviceID = await this.vue.$i100MeetSDK.i100Settings.getConfig('videoDeviceId')
         let isHasLocalId = false
         newCamDeviceList.forEach(item => {
            if(item.deviceId === localDeviceID){
               isHasLocalId = true
            }
         }) 
         if(cameraDeviceList.length && newCamDeviceList.length && newCamDeviceList.length > cameraDeviceList.length && isHasLocalId){
            loganLog('摄像头发生变化，旧的设备还存在 不切换新设备')
            return
         }
         const deviceId = await this.setCurrentCameraDevice()

         this.vue.$store.commit("meet/updateGlobalMeetState", {
            "cameraNoGrant":null
         })

         if (deviceId) {
            console.error('渲染摄像头预览执行了...')
            if (this.vue.$store.state.isShowVideoPanel) {
               this.startLocalCamera(240, 135, deviceId, deviceId +'_video')
            }
            if (this.vue.$store.state.isShowSettingPanel && this.vue.$store.state.settingPanelDefaultIndex === 2) {
               this.startLocalCamera(416, 234, deviceId, 'settingVideo')
            }
         }
      }
      // console.error(`deviceChangeEvent 执行了`)
   }
   if (navigator.mediaDevices.ondevicechange) {
      navigator.mediaDevices.ondevicechange = deviceChangeEvent.bind(this)
   }else {
      navigator.mediaDevices.ondevicechange = deviceChangeEvent.bind(this)

      // navigator.mediaDevices.addEventListener('devicechange', deviceChangeEvent.bind(this))
   }
}

// 获取设备分辨率
DeviceManager.prototype.getDeviceResolution = async function(deviceId) {
  try {
      let deviceResolution = await this.deviceRoomClient.getConstrinsSupport(deviceId)
      return deviceResolution
   } catch (error) {
      loganLog(`getDeviceResolution失败-----deviceId${deviceId}------error: ${JSON.stringify(error)}`)
   }
}

//音视频设备权限初始化
DeviceManager.prototype.initDevicePermission = function() {
      const deviceAuthValue = getDeviceAuth()
      if (DEVICE_AUTH_SUCCESS === deviceAuthValue) {
         this.init()
         return
      }

      checkDevicePermission()
      .then(() => {
        this.init()
        setDeviceAuth(DEVICE_AUTH_SUCCESS)
      })
      .catch(error => {
        console.error(error)
        setDeviceAuth(DEVICE_AUTH_FAIL)

      //   const tips = this.vue.$t('common.deviceAuthErrorTitle')
      //   const commonText = this.vue.$t('common.deviceAuthErrorMsg')
      // 登录界面无权限弹窗，1.6 web能入会关掉
      //   this.vue.$store.commit("meet/updateGlobalMeetState", {
      //     meetDialogInfo: {
      //       tips,
      //       commonText,
      //       showClose: false,
      //       width: 450
      //     }
      //   })
     })
}


// ------------------------------ 麦克风设备 ---------------------------------------

//设置要使用的麦克风
DeviceManager.prototype.setCurrentMicDevice = async function(deviceId, isSet = true, isInit = false) {
   const micDeviceList = this.vue.$store.state.device.micDeviceList

   const cacheDeviceId = await this.vue.$i100MeetSDK.i100Settings.getConfig('audioDeviceId')
   let queryDeviceId = deviceId || cacheDeviceId
   let currentDevice = micDeviceList.find(item => item.deviceId === queryDeviceId)
   if (!currentDevice && micDeviceList.length > 0) {
      currentDevice = micDeviceList[0]
      queryDeviceId = micDeviceList[0].deviceId
   }

   this.vue.$store.commit('device/setCurrentMicDevice', currentDevice)
   if (cacheDeviceId && queryDeviceId === cacheDeviceId && !isInit) {
      loganLog(`setCurrentMicDevice 由于重复设置return执行了`);
      return
   }

   await this.vue.$i100MeetSDK.i100Settings.setConfig('audioDeviceId', queryDeviceId)

   loganLog(`setCurrentMicDevice 执行了`);


   //---------------- SDK 设置麦克风测试 -------------------------
   if(isSet) {
      await this.vue.$i100MeetSDK.i100Settings.setCurrentMicDevice(queryDeviceId)
      console.error(`当前麦克风 ${queryDeviceId}`)
   }
}

//初始化麦克风音量
//修改:初始化不走缓存，默认100%
DeviceManager.prototype.initMicDeviceVolume = async function(isSet = true) {

   const volume = await this.vue.$i100MeetSDK.i100Settings.getConfig('audioValue')

   this.vue.$store.commit('device/setAudioVolumeValue', volume)

   //---------------- SDK 设置麦克风音量测试 -------------------------
   if (isSet) {
      this.vue.$i100MeetSDK.i100Settings.setCurrentMicDeviceVolume(Number(volume))
      console.error(`当前麦克风音量 ${volume}`)
   }
}

//获取当前麦克风设备的音量
// DeviceManager.prototype.getCurrentMicDeviceVolume = async function() {
//     //---------------- SDK 获取麦克风音量测试 -------------------------
//     return await this.vue.$i100MeetSDK.i100Settings.getCurrentMicDeviceVolume()
// }

//设置麦克风设备的音量
DeviceManager.prototype.setCurrentMicDeviceVolume = async function(number, isSet = true) {
   await this.vue.$i100MeetSDK.i100Settings.setConfig('audioValue', number)

   this.vue.$store.commit('device/setAudioVolumeValue', number)

   //---------------- SDK 设置麦克风音量测试 -------------------------
   if (isSet) {
      await this.vue.$i100MeetSDK.i100Settings.setCurrentMicDeviceVolume(Number(number))
   }
}

// ------------------------------ 扬声器设备 ---------------------------------------
//获取所有扬声器设备
// DeviceManager.prototype.getSpeakerDevicesList = async function() {

//    //---------------- SDK 扬声器列表测试 -------------------------
//    const speakerDevices = await this.vue.$i100MeetSDK.i100Settings.getSpeakerDevicesList()
//    console.log(`getSpeakerDeviceList:${JSON.stringify(speakerDevices)}`)
//    return speakerDevices
// }

//获取当前扬声器设备
// DeviceManager.prototype.getCurrentSpeakerDevice = async function() {

//     //---------------- SDK 获取当前扬声器测试 -------------------------
//     const speakerDeviceId = await this.vue.$i100MeetSDK.i100Settings.getCurrentSpeakerDevice()
//     console.log(`getCurrentSpeakerDevice:${speakerDeviceId}`)

//     const speakerDeviceList = this.vue.$store.state.device.speakerDeviceList

//     if (!speakerDeviceId) {

//       let queryDeviceId =  await this.vue.$i100MeetSDK.i100Settings.getConfig('speakerDeviceId')
//       if (!queryDeviceId && speakerDeviceList.length > 0) {
//           queryDeviceId = speakerDeviceList[0].deviceId
//       }
//       return speakerDeviceList.find(item => item.deviceId === queryDeviceId)
//    }

//     return speakerDeviceList.find(item => item.deviceId === speakerDeviceId)
// }

//设置要使用的扬声器
DeviceManager.prototype.setCurrentSpeakerDevice = async function(deviceId, audioViews, isInit = false) {
   const speakerDeviceList = this.vue.$store.state.device.speakerDeviceList

   const cacheDeviceId = await this.vue.$i100MeetSDK.i100Settings.getConfig('speakerDeviceId')
   let queryDeviceId = deviceId || cacheDeviceId
   let currentDevice = speakerDeviceList.find(item => item.deviceId === queryDeviceId)
   if (!currentDevice && speakerDeviceList.length > 0) {
      currentDevice = speakerDeviceList[0]
      queryDeviceId = speakerDeviceList[0].deviceId
   }

   this.vue.$store.commit('device/setCurrentSpeakerDevice', currentDevice)

   if (cacheDeviceId && queryDeviceId === cacheDeviceId && !isInit) {
      loganLog(`setCurrentSpeakerDevice 由于重复设置return执行了`);
      return
   }

   await this.vue.$i100MeetSDK.i100Settings.setConfig('speakerDeviceId', queryDeviceId)

   const elements = audioViews || document.querySelectorAll('.audioPlayoutVolumeItem')

   //---------------- SDK 设置扬声器测试 -------------------------
   await this.vue.$i100MeetSDK.i100Settings.setCurrentSpeakerDevice(queryDeviceId, elements)
}

//获取当前扬声器设备的音量
// DeviceManager.prototype.getPlayoutVolume = async function() {
//    //---------------- SDK 获取扬声器音量测试 -------------------------
//    return await this.vue.$i100MeetSDK.i100Settings.getCurrentSpeakerDeviceVolume()
// }

//初始化 当前扬声器设备的音量
DeviceManager.prototype.initPlayoutVolume = async function(audioViews, isSet = true) {

   const volume = await this.vue.$i100MeetSDK.i100Settings.getConfig('speakerValue')

   this.vue.$store.commit('device/setSpeakerVolumeValue', volume)

   const elements = audioViews || document.querySelectorAll('.audioPlayoutVolumeItem')

   //---------------- SDK 设置扬声器音量测试 -------------------------
   if (elements && isSet) {
      await this.vue.$i100MeetSDK.i100Settings.setCurrentSpeakerDeviceVolume(elements, Number(volume))
   }
}

//设置 当前扬声器设备的音量
DeviceManager.prototype.setPlayoutVolume = async function(audioViews, number, isUseYangShengQi = false) {

   this.vue.$store.commit('device/setSpeakerVolumeValue', number)

   await this.vue.$i100MeetSDK.i100Settings.setConfig('speakerValue',Number(number))


   if (isUseYangShengQi) {
      const elements = audioViews || document.querySelectorAll('.audioPlayoutVolumeItem')

      //---------------- SDK 设置扬声器音量测试 -------------------------
      await this.vue.$i100MeetSDK.i100Settings.setCurrentSpeakerDeviceVolume(elements, Number(number))
      console.error(`当前扬声器音量 ${number}`)
   }
}

// ------------------------------ 摄像头设备 ---------------------------------------
//摄像头列表
// DeviceManager.prototype.getCameraDevicesList = async function() {
//    //---------------- SDK 摄像头列表测试 -------------------------
//    const camDevices = await this.vue.$i100MeetSDK.i100Settings.getCameraDevicesList()
//    console.log(`getCameraDevicesList:${JSON.stringify(camDevices)}`)
//    return camDevices
// }

//获取当前摄像头设备
DeviceManager.prototype.getCurrentCameraDevice = async function() {

   //---------------- SDK 获取当前摄像头测试 ------------------------
   const camDeviceList = this.vue.$store.state.device.cameraDeviceList

   let queryDeviceId =  await this.vue.$i100MeetSDK.i100Settings.getConfig('videoDeviceId')
   if (!queryDeviceId && camDeviceList.length > 0) {
         queryDeviceId = camDeviceList[0].deviceId
   }
   return camDeviceList.find(item => item.deviceId === queryDeviceId)
}

//初始化( 摄像头设备
DeviceManager.prototype.initCurrentCameraDevice = async function() {
   const deviceInfo = await this.getCurrentCameraDevice() || {}
   if (deviceInfo.deviceId) {
      loganLog(`初始化摄像头${deviceInfo.deviceId}`)
      console.error(`初始化摄像头${deviceInfo.deviceId}`)
      try {
         await this.vue.$i100MeetSDK.i100Settings.setCurrentCameraDevice(deviceInfo.deviceId)
         loganLog(`摄像头设备 initCurrentCameraDevice 执行了`);
      } catch (error) {
         loganLog(`摄像头设备 initCurrentCameraDevice失败了 error:${JSON.stringify(error)}`);
      }
   }
}

//设置摄像头
DeviceManager.prototype.setCurrentCameraDevice = async function(deviceId, isSet = true, isInit = false) {
   const camDeviceList = this.vue.$store.state.device.cameraDeviceList

   let cacheDeviceId = await this.vue.$i100MeetSDK.i100Settings.getConfig('videoDeviceId')
   let queryDeviceId = deviceId || cacheDeviceId
   let currentDevice = camDeviceList.find(item => item.deviceId === queryDeviceId)
   if (!currentDevice && camDeviceList.length > 0) {
      currentDevice = camDeviceList[0]
      queryDeviceId = camDeviceList[0].deviceId
   }

   this.vue.$store.commit('device/setCurrentCameraDevice', currentDevice)
   loganLog(`摄像头设备 setCurrentCameraDevice 执行了`);

   if (cacheDeviceId && queryDeviceId === cacheDeviceId && !isInit) {
      loganLog(`setCurrentCameraDevice 由于重复设置return执行了`);
      return queryDeviceId
   }

   await this.vue.$i100MeetSDK.i100Settings.setConfig('videoDeviceId', queryDeviceId)

   if (queryDeviceId && isSet) {

      //---------------- SDK 设置当前摄像头测试 -------------------------
      try {
         await this.vue.$i100MeetSDK.i100Settings.setCurrentCameraDevice(queryDeviceId)
         loganLog(`摄像头设备 setCurrentCameraDevice 执行了`);
      } catch (error) {
         loganLog(`摄像头设备 setCurrentCameraDevice失败了 error:${JSON.stringify(error)}`);
      }

      const peerId = this.vue.$configs.peerId
      const selfInfo = this.vue.$store.getters["member/getUser"](peerId)
      if (selfInfo.isUseShiPin && selfInfo.userId) {

         //渲染video元素
         const videoEl = document.getElementById(`video-${peerId}`)
         if (videoEl) {
            await this.vue.$i100MeetSDK.i100MeetingControl.startLocalVideo(videoEl)
         }

         const videoMainEl = document.getElementById(`main-video-${peerId}`)
         if (videoMainEl) {
            await this.vue.$i100MeetSDK.i100MeetingControl.startLocalVideo(videoMainEl)
         }

         const videoTabEl = document.getElementById(`tab2-video-${peerId}`)
         if(videoTabEl) {
            await this.vue.$i100MeetSDK.i100MeetingControl.startLocalVideo(videoTabEl)
         }

         loganLog(`摄像头设备 当前摄像头 ${queryDeviceId}`);
      }

   }
   return queryDeviceId
}

//打开本地视频
DeviceManager.prototype.startLocalCamera = async function(width, height, deviceId, elementId) {
   let video = document.getElementById(elementId)
   if (!video) return

   if (this.stopCameraInterval) {
      clearInterval(this.stopCameraInterval)
      this.stopCameraInterval = null
   }

   try {
      const configObject = {
         width: width,
         height: height,
         deviceId: { ideal: deviceId }
      }
      const mediaStream = await navigator.mediaDevices.getUserMedia({ video : configObject})
      this.vue.$store.commit('device/setVideoSource', mediaStream.getVideoTracks())
      this.vue.$store.commit('device/setVideoElement', video)

      video.srcObject = mediaStream
      video.play()
   } catch (error) {
      loganLog(`打开本地视频 start local camera error:${error}`);
   }
}

//关闭本地视频
DeviceManager.prototype.stopLocalCamera = function(isInterval = false) {
   const self = this
   const stopVideoSourceFn = () => {
      //释放资源
      let videoElement = self.vue.$store.state.device.videoElement
      if (videoElement) {
         videoElement.src = ""
         videoElement.srcObject = null
      }

      let videoSource = self.vue.$store.state.device.videoSource
      if (videoSource && Array.isArray(videoSource) && videoSource.length > 0) {
         videoSource.forEach(item => {
            item.stop()
         })
         self.vue.$store.commit('device/setVideoSource', null)
         if (self.stopCameraInterval) {
            clearInterval(self.stopCameraInterval)
            self.stopCameraInterval = null
         }
      }
   }

   !isInterval && stopVideoSourceFn()
   if (isInterval && !self.stopCameraInterval) {
      self.stopCameraInterval = setInterval(stopVideoSourceFn, 1000)
   }
}

//启动本地麦克风测试
DeviceManager.prototype.enableMicTest = async function() {
   await this.vue.$i100MeetSDK.i100Settings.enableMicTest()
   // try {
   //    //实例化AudioContext
   //    const audioContextCtor = window.AudioContext
	// 			|| window.webkitAudioContext
	// 			|| false

   //    this.audioContext = new audioContextCtor()
   // } catch (e) {
   //    console.error('Web Audio API is not supported')
   //    return
   // }

   // //SoundMeter声音测量,用于做声音音量测算使用的
   // this.soundMeter = new SoundMeter(this.audioContext);

   // try {
   //    this.micStream = await navigator.mediaDevices.getUserMedia({ audio: true });
   //    //将声音测量对象与流连接起来
   //    this.soundMeter.connectToSource(this.micStream);
   //    //开始实时读取音量值  每隔200毫秒调用一次soundMeterProcess函数,模拟实时检测音频音量
   //    this.micTestVolumeQueryInterval = setInterval(() => {
   //       //读取音量值,再乘以一个系数,可以得到音量条的宽度
   //       if (this.soundMeter) {
   //          this.micTestVolume = this.soundMeter.instant
   //          //console.log(`micTestVolume = ${this.micTestVolume}`)
   //       }
   //    }, 500)
   // } catch (error) {
   // }
}

//停止本地麦克风测试
DeviceManager.prototype.disableMicTest = function() {
   this.vue.$i100MeetSDK.i100Settings.disableMicTest()
   // //关闭定时器
   // if (this.micTestVolumeQueryInterval) {
   //    clearInterval(this.micTestVolumeQueryInterval)
   //    this.micTestVolumeQueryInterval = null
   // }
   // //断开sound meter
   // this.soundMeter && this.soundMeter.mic && this.soundMeter.stop()
   // //关闭stream
   // if (this.micStream) {
   //    var tracks, i, len;
   //    //判断是否有getTracks方法
   //    if (this.micStream.getTracks) {
   //       //获取所有的Track
   //       tracks = this.micStream.getTracks();
   //       //迭代所有Track
   //       for (i = 0, len = tracks.length; i < len; i += 1) {
   //          //停止每个Track
   //          tracks[i].stop();
   //       }
   //    } else {
   //       //获取所有的音频Track
   //       tracks = this.micStream.getAudioTracks();
   //       //迭代所有音频Track
   //       for (i = 0, len = tracks.length; i < len; i += 1) {
   //          //停止每个Track
   //          tracks[i].stop();
   //       }
   //    }
   // }
   // //变量重置
   // this.audioContext = null
   // this.soundMeter = null
   // this.micTestVolume = 0
   // this.micStream = null
}

//获取麦克风音量范围
DeviceManager.prototype.getMicTestVolume = async function() {
   //return this.micTestVolume
   return this.vue.$i100MeetSDK.i100Settings.getMicTestVolume()
}

// -------------------- 音视频 技术指标统计 -----------------
DeviceManager.prototype.computeLocalStats = async function() {
   if (this.vue.$i100MeetSDK.i100MeetingControl) {
      //从transport中获取本地音视频信息
      const localTransportStats = await this.vue.$i100MeetSDK.i100MeetingControl.getSendTransportLocalStats()
      //console.error('--------- 本地音视频信息 -----------')
      const that = this, stats = {}
      localTransportStats && localTransportStats.forEach(item => {
         //console.error(JSON.stringify(item))
         if (item.totalRoundTripTime > 0 && item.responsesReceived > 0) {
            stats.totalRoundTripTime = item.totalRoundTripTime
            stats.responsesReceived = item.responsesReceived
         }
         if (item.currentRoundTripTime > 0) {
            stats.currentRoundTripTime = item.currentRoundTripTime
         }
         if (item.totalRoundTripTime > 0 && item.roundTripTimeMeasurements > 0) {
            stats.totalRoundTripTime = item.totalRoundTripTime
            stats.roundTripTimeMeasurements = item.roundTripTimeMeasurements
         }

         if (item.hasOwnProperty('packetsLost')) {
            stats.packetsLost = item.packetsLost
         }
         if (item.hasOwnProperty('packetsReceived')) {
            stats.packetsReceived = item.packetsReceived
         }
         if (item.hasOwnProperty('packetsSent')) {
            stats.packetsSent = item.packetsSent
         }
      })
      //网络延迟
      let rtt = 0
      if(stats.currentRoundTripTime) {
         //console.error('延迟数据=>currentRoundTripTime')
         rtt = Math.floor(stats.currentRoundTripTime * 1000)
      }else if (stats.totalRoundTripTime && stats.responsesReceived) {
         //console.error('延迟数据=>totalRoundTripTime/responsesReceived')
         rtt = Math.floor(stats.totalRoundTripTime / stats.responsesReceived * 1000)
      }else if(stats.totalRoundTripTime && stats.roundTripTimeMeasurements){
         //console.error('延迟数据=>totalRoundTripTime/roundTripTimeMeasurements')
         rtt = Math.floor(stats.totalRoundTripTime / stats.roundTripTimeMeasurements * 1000)
      }
      that.vue.$store.commit('device/updateStats', { rtt : rtt })

   }
}


export default DeviceManager
