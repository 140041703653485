import Cookies from 'js-cookie'
import { LANG_ZH, LANG_EN, LANG_AUTO } from '@/constant/index'
import cryptoRandomString from 'crypto-random-string'

const TokenKey = 'ybToken'
const refreshTokenKey  = 'ybRefreshToken'
const clientIdKey = 'cId'
const clientSecretKey = 'cs'

const avatarKey = 'avatar'
const uuidKey = 'uuid'

const acceptLanguageKey = 'acceptLanguage'
const mobileKey = 'loginMobile'
const phoneKey = 'loginPhone'
const emailKey = 'loginEmail'
const localConferenceNoKey = 'localConferenceNoKey'
const displayNameKey = 'displayNameKey'
const mopanLinks = 'mopanLinks'
const mopanToken = 'mopanToken'

const conferenceTokenKey = 'X-Conference-Token'
const channelTokenKey = 'X-Channel-Token'
const reJoinUid = 'reJoinUid'


// 获取会议服务token
export function getConferenceToken () {
    return localStorage.getItem(conferenceTokenKey)
}
// 设置会议服务token
export function setConferenceToken (token) {
    localStorage.setItem(conferenceTokenKey, token)
}
// 移除会议服务token
export function removeConferenceToken () {
    localStorage.removeItem(conferenceTokenKey)
}


// 获取IM token
export function getChannelToken () {
    return localStorage.getItem(channelTokenKey)
}
// 设置IM token
export function setChannelToken (token) {
    localStorage.setItem(channelTokenKey, token)
}
// 移除IM token
export function removeChannelToken () {
    localStorage.removeItem(channelTokenKey)
}


/**
 * 存储会议相关信息
 * @param {*} meetToken 
 * @param {*} imToken 
 */
export function setMeetInfo(meetToken, imToken) {
    setConferenceToken(meetToken)
    setChannelToken(imToken)
}

/**
 * 清空会议相关信息
 */
export function removeMeetInfo() {
    removeConferenceToken()
    removeChannelToken()
}




// 获取登录token
export function getToken() {
    return Cookies.get(TokenKey)
}
// 登录token赋值
export function setToken(token) {
    Cookies.set(TokenKey, token)
}
// 移除登录token
export function removeToken() {
    Cookies.remove(TokenKey)
}


// refreshToken
export function getRefreshToken() {
    return Cookies.get(refreshTokenKey)
}

export function setRefreshToken(token) {
    Cookies.set(refreshTokenKey, token)
}

export function removeRefreshToken() {
    Cookies.remove(refreshTokenKey)
}


// client_id
export function getClientId() {
    return Cookies.get(clientIdKey)
}

export function setClientId(val) {
    Cookies.set(clientIdKey, val)
}

export function removeClientId() {
    Cookies.remove(clientIdKey)
}

// client_secret
export function getClientSecret() {
    return Cookies.get(clientSecretKey)
}

export function setClientSecret(val) {
    Cookies.set(clientSecretKey, val)
}

export function removeClientSecret() {
    Cookies.remove(clientSecretKey)
}



// 头像
export function getAvatar () {
    return Cookies.get(avatarKey)
}
export function setAvatar (avatar) {
    return Cookies.set(avatarKey, avatar)
}
export function removeAvatar () {
    return Cookies.remove(avatarKey)
}


// 设置uuid
export function setUuid (token) {
    return Cookies.set(uuidKey, token)
}
export function getUuid () {
    let uuid = Cookies.get(uuidKey)
    if (!uuid) {
      uuid = cryptoRandomString({length: 10})
      setUuid(uuid)
    }
    return uuid
}
// 移除uuid
export function removeUuid () {
    return Cookies.remove(uuidKey)
}

// 获取语言值
export function getAcceptLanguage() {
    const localLang = getLocalLanguage()
    const sysLang = navigator.language === LANG_ZH ? LANG_ZH: LANG_EN

    if (!localLang) { // 不存在，存储自动选项
        setLocalLanguage(LANG_AUTO)
        return sysLang
    } else { // 存在
        if (localLang === LANG_AUTO) { // 自动
            return sysLang
        } else {
            return localLang
        }
    }
}
export function isZhLang() {
    return getAcceptLanguage() === LANG_ZH
}
export function getLocalLanguage() {
    return localStorage.getItem(acceptLanguageKey)
}
export function setLocalLanguage(val) {
    localStorage.setItem(acceptLanguageKey, val)
}
export function removeLocalLanguage() {
    localStorage.removeItem(acceptLanguageKey)
}



// 获取再次入会uid
export function getReJoinUid () {
    return localStorage.getItem(reJoinUid)
}
// 保存再次入会uid
export function setReJoinUid (val) {
    localStorage.setItem(reJoinUid, val)
}

// 保存验证码手机号
export function getMobile () {
    return localStorage.getItem(mobileKey)
}
export function setMobile (val) {
    localStorage.setItem(mobileKey, val)
}
export function removeMobile () {
    localStorage.removeItem(mobileKey)
}

// 保存密码手机号
export function getPhone () {
    return localStorage.getItem(phoneKey)
}
export function setPhone (val) {
    localStorage.setItem(phoneKey, val)
}
export function removePhone () {
    localStorage.removeItem(phoneKey)
}

// 保存邮箱
export function getEmail () {
    return localStorage.getItem(emailKey)
}
export function setEmail (val) {
    localStorage.setItem(emailKey, val)
}
export function removeEmail () {
    localStorage.removeItem(emailKey)
}

// 会议号
export function getLocalConferenceNo () {
    return localStorage.getItem(localConferenceNoKey)
}
export function setLocalConferenceNo (val) {
    localStorage.setItem(localConferenceNoKey, val)
}
export function removeLocalConferenceNo () {
    localStorage.removeItem(localConferenceNoKey)
}

// 会议展示名
export function getDisplayName () {
    return localStorage.getItem(displayNameKey)
}
export function setDisplayName (val) {
    localStorage.setItem(displayNameKey, val)
}
export function removeDisplayName () {
    localStorage.removeItem(displayNameKey)
}

// 工作台路由
export function getMopanLinks () {
    return sessionStorage.getItem(mopanLinks)
}
export function setMopanLinks (val) {
    sessionStorage.setItem(mopanLinks, val)
}
export function removeMopanLinks () {
    sessionStorage.removeItem(mopanLinks)
}
// 工作台token

export function getMeetToken () {
    return localStorage.getItem(mopanToken)
}
export function setMeetToken (val) {
    localStorage.setItem(mopanToken, val)
}
export function removeMeetToken () {
    localStorage.removeItem(mopanToken)
}



// reset
export function removeUserInfo() {
    removeToken()
    removeRefreshToken()
    removeClientId()
    removeClientSecret()
    removeAvatar()
    removeMeetToken()
    removeMopanLinks()
}