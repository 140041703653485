// 设置设备缓存值
const deviceInfoKey = "LOCALDEVICESET";

const deviceAuthKey = 'deviceAuth'

/**
 * audioDeviceId: ''
 * audioValue: 50,
 *
 * speakerDeviceId: ''
 * speakerValue: ''
 *
 * videoDeviceId: ''
 * videoQuality: '' //视频画质
 *
 * joinDuration: 1 //是否显示我的参会时长 1|0 默认1
 * joinDurationTag: 0 // 是否首次勾选参会时长
 * shareLimit: 0 //是否设置我的屏幕共享限制 0|1 默认0
 * shareLimitValue: '' //共享限制每秒帧数
 */

export function getLocalDeviceInfo() {
  const data = localStorage.getItem(deviceInfoKey);

  return data ? JSON.parse(data) : {};
}

export function setLocalDeviceInfo(data) {
const localData = getLocalDeviceInfo();

  localStorage.setItem(
    deviceInfoKey,
    JSON.stringify({
      ...localData,
      ...data,
    })
  );
}

export function removeLocalDeviceInfo() {
  localStorage.removeItem(deviceInfoKey);
}


export function getDeviceAuth () {
    return localStorage.getItem(deviceAuthKey)
}
export function setDeviceAuth (val) {
    localStorage.setItem(deviceAuthKey, val)
}
export function removeDeviceAuth () {
    localStorage.removeItem(deviceAuthKey)
}



export function checkDevicePermission() {
  return new Promise(async (resolve, reject) => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({
        video: true,
        audio: true,
      });

      stream.getTracks().forEach((track) => track.stop());
      console.error("摄像头、麦克风权限设置成功了");
      resolve();
    } catch (error) {
      console.error("摄像头、麦克风权限设置失败" + error);
      reject(error);
    }
  })
}
