export const LANG_ZH = 'zh-CN'
export const LANG_EN = 'en-US'
export const LANG_AUTO = 'auto'

export const DEVICE_AUTH_SUCCESS = '1'
export const DEVICE_AUTH_FAIL = '2'

// 布局配置
export const LAYOUT_CONFIG = {
  GALLERY9: 1,
  GALLERY25: 2,
  COLUMN: 3,
  ROW: 4,
  FULL: 5
}

export const ROLE_CODE = {
  HOST: 20, // 主持人
  CO_HOST: 10, // 联席主持人
  USER: 0 // 普通用户
}

// 排序权重
export const WEIGHT_CONFIG = {
  SELF: 9, // 自己
  HOST: 3,
  CO_HOST: 2,
  USER: 1
}

export const MAX_CONF_TIME = 100 * 24 * 60 * 60 * 1000

export const CONTROL_COMMAND = {
  HOST_JOIN_ROOM: 'hostJoinRoom', // 主持人加入会议
  AUTOMATICGRANT: 'automaticGrant', // 自动选举主持人
  CHANGE_NAME: 'changeNameByHost', // 主持人更改参会人名字

  GRANT: 'grant', // 授权角色变更
  
  TRANSFER_HOST: 'transferHost', // 转移主持人
  RECOVER_HOST_PERMISSION: 'recoverHostPermission', // 收回主持人权限
  APPLY_HOST: 'applyHostPermission', // 申请成为主持人

  SINGLE_MUTE: 'singleMute', // 单个静音
  SINGLE_UNMUTE: 'singleUnMute', // 单个取消静音

  SET_FOCUS: 'setFocusScreen', // 设置焦点画面
  CANCE_FOCUS: 'cancelFocusScreen', // 取消焦点画面

  STOP_SHARE: 'stopShare', // 停止共享
  SHIELD_VIDEOS: 'shieldVideos', // 屏蔽参会人视频

  START_RECORD: 'startRecord', // 参会人开始录制
  STOP_RECORD: 'stopRecord', // 停止参会人录制

  PAUSE_RECORD: 'pauseRecording', // 暂停录制
  RESUME_RECORD: 'resumeRecording', // 恢复录制
  
  REMOVE_SELF: 'removeSelf', // 踢出自己的其他设备
  REMOVE_USER: 'removeUser', // 踢出其他用户

  RAISE_HAND: 'raiseHand', // 举手
  SINGLE_HAND_DOWN: 'singleHandsDown', // 单个成员手放下
  ALL_HAND_DOWN: 'allHandsDown', // 多个成员手放下

  // 全局会议通知
  END_MEET: 'endConference', // 结束会议
  FORCE_END_MEET: 'forceEndConference', // 强制结束会议
  
  LOCK_CONFERENCE: 'lockConference', // 锁定会议
  UNLOCK_CONFERENCE: 'unLockConference', // 解锁会议
  MATURITY_NOTICE: 'maturityNotice', // 免费会议结束前通知

  ALLOW_SELF_UNMUTE: 'allowSelfUnmute', // 允许成员自我解除静音
  FORBID_SELF_UNMUTE: 'forbidSelfUnmute', // 禁止成员自我解除静音
  
  OPEN_PLAY_TIPS: 'openPlayTips', // 成员加入会议时开启播放提示音
  CLOSE_PLAY_TIPS: 'closePlayTips', // 成员加入会议时关闭播放提示音

  OPEN_MUTE_JOIN_MEETING: 'openMuteJoinMeeting', // 成员加入会议开启静音
  CLOSE_MUTE_JOIN_MEETING: 'closeMuteJoinMeeting', // 成员加入会议关闭静音

  ALL_FORCE_MUTE: 'allForceMute', // 全体强制静音
  ALL_UNFORCE_MUTE: 'allUnForceMute', // 全体非强制静音
  ALL_UNMUTE: 'allUnMute', // 全体解除静音

  SHARE_PERMISSIONS_ALL: 'sharePermissionsForAll', // 所有人可以共享
  SHARE_PERMISSIONS_HOST: 'sharePermissionsForHost', // 仅主持人可共享

  RECORD_PERMISSIONS_ALL: 'recordPermissionForAll', // 所有人都可以录制
  RECORD_PERMISSIONS_HOST: 'recordPermissionForHost', // 仅主持人录制

  SCHEDULE_PERMISSIONS_ALL: 'settingAgendaPermissionForAll', // 会议议程所有人可见
  SCHEDULE_PERMISSIONS_HOST: 'settingAgendaPermissionForHost', // 会议议程仅主持人可见
  
  SCREEN_SHOT:'screenShot',//截图
  START_CLOUDRECORD : "startCloudRecord", //开始云录制
  START_BACK_CLOUDRECORD : "startBackCloudRecord",//开始云录准备好了 
  PAUSE_CLOUDRECORD : "pauseCloudRecord", //暂停云录制
  RESUME_CLOUDRECORD : "resumeCloudRecord", //恢复云录制
  RESUME_BACK_CLOUDRECORD : "resumeBackCloudRecord", //恢复云录制准备好了
  STOP_CLOUDRECORD : "stopCloudRecord", //结束云录制

  INITIATE_QUESTIONNAIRE : "initiateQuestionnaire", //收到问卷消息

}


//测试网络 图片url常量 TODO:
export const NETWORK_TEST_URL = 'https://www.100doc.com.cn/themes/simpleboot3/public/assets/images/top_logo.png'

// 最大分享帧率
export const MAX_SHARE_VIDEO_FPS = 10

//节流时间
export const THROTLE_TIME = 2000

//音频设备默认音量值
export const DEFAULT_VOLUME = 100

export const MAX_AUDIO_TRANSPORT = 17

//chrome最低版本限制
export const CHROME_VERSON_MIN = 98

//edge最低版本限制
export const EDGE_VERSON_MIN = 92

//safari最低版本限制
export const SAFARI_VERSON_MIN = 14

//SDK通知需要toast
export const TOAST_ERR_CODE = [80000306]

  
//设备异常的类型
export const DEVICE_ERR_CODE = [80000301,80000303,80000332,80000304,80000334]
  
//SDK通知需要弹窗错误码 todo 80000332合上笔记本再打开会通知需要解决
export const DIALOG_ERR_CODE = [80000301,80000302,80000303,80000304,80000305,80000333,80000334,80000310,80000311]

//SDK通知需要弹窗并返回的错误码
export const DIALOG_BACK_ERR_CODE = [80000101,80000102,80000103,80000104,80000105,80000106,80000107,80000206,80000208]

//SDK通知需要loading的错误码
export const NEED_LOADING_CODE = [80000502,80000503,80000504,80000505,80000508,80000202,80000203,80000204,80000205]

//SDK错误提示映射
export const ERR_DATE_MAP = {
  80000101:{
    title:"加入会议失败",
    describe:"",
  },
  80000102:{
    title:"加入会议失败",
    describe:"",
  },
  80000103:{
    title:"加入会议失败",
    describe:"",
  },
  80000104:{
    title:"加入会议失败",
    describe:"",
  },
  80000105:{
    title:"加入会议失败",
    describe:"",
  },
  80000106:{
    title:"加入会议失败",
    describe:"",
  },
  80000206:{
    title:"加入会议失败",
    describe:"网络连接异常，请检查后重试",
  },
  80000207:{
    title:"离开会议失败",
    describe:"",
  },
  80000208:{ //断网重连后加入失败 有可能是会议已结束
    title:"加入会议失败",
    describe:"会议已结束",
  },
  80000301:{
    title:"打开摄像头失败",
    describe:"",
  },
  80000302:{
    title:"打开麦克风失败",
    describe:"",
  },
  80000303:{
    code:80000303,
    title:"打开摄像头失败，无法获取摄像头权限",
    describe:"请检查是否给医百会议开启了摄像头权限，或在给予权限后请尝试重启医百会议",
  },
  80000304:{
    code:80000304,
    title:"打开麦克风失败，无法获取麦克风权限",
    describe:"请检查是否给医百会议开启了麦克风权限，或在给予权限后请尝试重启医百会议",
  },
  80000310:{
    code:80000310,
    title:"打开摄像头失败",
    describe:"摄像头被其他应用占用，请检查并在其他应用关闭摄像头后重试",
  },
  80000311:{
    code:80000311,
    title:"打开麦克风失败",
    describe:"麦克风被其他应用占用，请检查并在其他应用关闭摄像头后重试",
  },
  80000332:{
    title:"无摄像头设备",
    code:80000332,
    describe:"未检测到您摄像头设备，请插入摄像头设备以供使用",
  },
  80000333:{
    title:"无音频（麦克风）设备",
    describe:"未检测到您麦克风设备，请插入麦克风设备以供使用",
  },
  80000334:{
    code:80000334,
    title:"无音频（扬声器）设备",
    describe:"未检测到您的扬声器设备，请插入扬声器设备以供使用",
  }
}
