<template>
  <div id="app">
    <div class="global">
      <extend></extend>
      <extend-date></extend-date>
    </div>

    <router-view />

    <meetDialog
      :meetDialogInfo="meetDialogInfo"
      @close="dialogCloseHandle"
    />
  </div>
</template>
<script>
import Vue from 'vue'
import DeviceManager from '@/lib/core/deviceManager'
import cryptoRandomString from 'crypto-random-string'
import { setUuid } from "@/utils/auth"
import { getBrowser } from "@/utils/tool"
import meetDialog from "@/components/dialog/index.vue"
import { CHROME_VERSON_MIN, EDGE_VERSON_MIN, SAFARI_VERSON_MIN } from "@/constant/index"

export default {
  components: {
    meetDialog
  },
  computed: {
    meetDialogInfo() {
      return this.$store.state.meet.meetDialogInfo
    }
  },
  created() {
    setUuid(cryptoRandomString({length: 10}))

    //** 浏览器以及版本控制
    //** chrome(内核75)、edge、360(内核78)、360极速(内核78)
    const browser = getBrowser()
    
    if (!browser.chrome && !browser.edge) {
      this.$router.push({ name: "upgrade" });
      return
    }

    //禁用safari
    if (browser.safari && browser.safari>1) {
      this.$router.push({ name: "upgrade" });
      return
    }

    //chrome浏览器版本不支持
    if (browser.chrome && browser.chrome < CHROME_VERSON_MIN) {
      this.$router.push({ name: "upgrade" });
      return
    }

    if ((browser.chrome && browser.chrome < CHROME_VERSON_MIN) 
      || (browser.edge && browser.edge < EDGE_VERSON_MIN)
      || (browser.safari && browser.safari < SAFARI_VERSON_MIN)
      ) {
      this.$confirm(this.$t('login.versionTips'),this.$t('login.versionTitle'),{
          showCancelButton:false,
          showConfirmButton:false,
          closeOnClickModal:false,//是否可通过点击遮罩关闭
          closeOnPressEscape:false,//是否可通过按下 ESC 键关闭 
          showClose:false,//是否显示右上角关闭按钮
          center: true
        })
      return
    }

    //设备实例初始化以及权限校验
    console.error('设备实例初始化以及权限校验开始了')
    Vue.prototype.$deviceControl = new DeviceManager(this)
    this.$deviceControl.initDevicePermission()

  },
  methods: {
    dialogCloseHandle() {
      this.$store.commit("meet/updateGlobalMeetState", {
        meetDialogInfo: null
      })
    }
  }
};
</script>

<style lang="scss">
html,
body {
  height: 100%;
}
html,body,header,footer,a,select,section,
div,p,span,img,li,ul,dl,dt,dd,table,
th,tr,td,h1,h2,h3,h4,h5,h6{
  -webkit-tap-highlight-color:rgba(255,0,0,0);
  border:none;
  outline:none;
  -webkit-touch-callout: none;
  user-select: none;
}

#app {
  height: 100%;
}

.minSize {
  height: 100%;
  min-width: 1240px;
  min-height: 700px;
}

.ellipsis {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.slider {
  .el-slider__button {
    width: 10px;
    height: 10px;
  }
}

// el-notify
.el-notification {
  margin-top: 150px;
  .user-error-notify {
    .item {
      display: flex;
      // align-items: center;
      color: #999;
      .item-left {
        flex-shrink: 0;
      }

      .item-right {
        width: 175px;
        word-wrap: break-word;
        white-space: normal;
        word-break: break-all;
      }
    }
  }
}

.waitMeetLoading {
  display: flex;
  justify-content: center;

  .el-loading-spinner {
    width: 115px;
    background: #333333;
    padding: 30px 20px;
    border-radius: 10px;
    transform: translateY(-50%);
    margin-top: 0 !important;
  }
  
  .el-icon-loading {
    font-size: 24px !important;
    color: #fff !important;
    margin-bottom: 10px;
  }

  .el-loading-text {
    font-size: 16px !important;
    color: #fff !important;
  }
}

.el-input__suffix {
    .el-input__suffix-inner {
      border-color: none;
      .el-icon-circle-close:before {
        font-family: 'iconfont' !important;
        content: "\e711" !important;
        font-size: 18px;
      }
    }
}

.img-common {
  display: block;
  width: 100%;
  height: 100%;
}

.language {
  .el-select {
    .el-input {

      &.is-focus {
        .el-input__inner {
          border-color: #fff;
          color: #fff;
        }
      }

      .el-input__inner {
        background-color: #1ab370;
        color: #fff;
        -webkit-box-shadow: none;

        &:focus {
          border-color: #fff;
        }
      }

      .el-select__caret {
        color: #fff;
      }
    }
  }
}

.el-select-dropdown__item{
  padding: 0 20px !important;
}

.el-dialog-drag {
  .el-dialog__header {
    padding: 0 16px;
    line-height: 48px;
    text-align: center;
    position: relative;
    background-color: #fff;

    .el-dialog__title {
      font-size: 16px;
      font-weight: 500;
      color: #333;

      // text-shadow: 0 10px 20px rgb(0 0 0 / 20%);
    }
  }
}

.wenjuann-dialog-drag {
  .el-dialog__header {
    line-height: 24px;
    height: 24px;
    .el-dialog__title {
      display: none;
    }
    .el-dialog__headerbtn{
      top: 8px;
      right:8px
    }
  }
}
.layoutCoreOne {
  display: flex;
  flex-grow: 1;
}

.advanceElDialog {
  .el-dialog__body {
    padding: 0;
  }
}
</style>