/**
 * userList数组中对象结构
 * - userId: roomid + userId 唯一标记
 * - userName: userName,
 * - avatarUrl: 头像
 * - isUseHuaTong: false, // 是否开启话筒
 * - isUseHuaTongError: false, // 话筒状态是否error
 * - isUseShiPin: false, // 是否开启视频
 * - isUseYangShengQi: true, // 是否开启扬声器
 * - isShare: false, // 是否共享中
 * - sharePaused: false // 是否共享暂停中
 * - isRecord: false, // 是否正在录制
 * - recordPaused: false, // 录制是否暂停
 * 
 * - isSpeaking: false, // 是否说话中
 *
 * - roleCode: 20, // 角色信息
 * - isRaiseHand: false, // 是否正在举手

 * - isDbClick: false, // 是否被锁定了
 * - timeMs // 入会时间戳
 */
import Vue from 'vue'

import { WEIGHT_CONFIG, MAX_CONF_TIME, ROLE_CODE } from '@/constant/index'

const state = {
    userList: [], // 用户列表（包含顶部共享画面的）
    pageIndex: 1, // 当前页
    pageSize: 9, // 每页多少条

    totolUserNumber: 0, // 会场中的总人数

    audioList: [], // 音频通道列表

    speakList: [], // 发言人列表
    lastSpeaker: null // 最新的发言人
}

const mutations = {
    // 添加用户，把一个用户插入到末尾 TODO: 补充去重逻辑
    addUser(state, user) {
        for (let index = 0; index < state.userList.length; index++) {
            const userItem = state.userList[index]

            if (!userItem.isTopShare) {

                delete userItem._old

                userItem._old = JSON.parse(JSON.stringify(userItem))
            }
        }

        state.userList.push(user)
    },
   
    // 添加用户, 把一个用户插入到第一个位置
    unshiftUser(state, user){
        state.userList.unshift(user)
    },

    // 删除用户
    removeUser(state, user) {
        let i = -1;

        for (let index = 0; index < state.userList.length; index++) {
            const userItem = state.userList[index]

            if (!userItem.isTopShare) {
                delete userItem._old
                userItem._old = JSON.parse(JSON.stringify(userItem))

                if (userItem.userId === user.userId) {
                    i = index;
                }
            }
        }

        if (i > -1) {
            state.userList.splice(i, 1);
        }
    },

    // 设置总人数
    updateTotolUserNumber: function(state, val) {
        state.totolUserNumber = val
    },

    // 删除第一个位置的分享画面
    removeShareView(state) {
        let topIndex = -1

        for (let i = 0; i < state.userList.length; i++) {
            const item = state.userList[i]

            if (item.isTopShare) {
                topIndex = i
                break
            }
        }

        if (topIndex > -1) {
            state.userList.splice(topIndex, 1)
        }
    },
    
    // 改变userList中某一项的值, user: {} 一个用户对象
    updateUser(state, user) {
        for (let index = 0; index < state.userList.length; index++) {
            const userItem = state.userList[index]
            if (!userItem.isTopShare && userItem.userId === user.userId) {

                delete userItem._old

                userItem._old = JSON.parse(JSON.stringify(userItem))

                for (const key in user) {
                    userItem[key] = user[key]
                }
                break;
            }
        }
    },

    // 更新顶部共享画面
    updateTopShare(state, user) {
        for (let i = 0; i < state.userList.length; i++) {
            const item = state.userList[i]

            if (item.isTopShare) {
                for (const key in user) {
                    item[key] = user[key]
                }
                break
            }
        }
    },

    // 设置第几页
    updatePageIndex: function(state, pageIndex) {
        state.pageIndex = pageIndex
    },
    // 设置每页多少条
    updatePageSize: function(state, pageSize) {
        state.pageSize = pageSize
    },


    // 添加speak
    addSpeak(state, user) {
        state.speakList.push(user)
    },

    // 删除speak
    removeSpeak(state, user) {
        let i = -1;

        for (let index = 0; index < state.speakList.length; index++) {
            const userItem = state.speakList[index]

            if (userItem.userId === user.userId) {
                i = index;
                break;
            }
        }

        if (i > -1) {
            state.speakList.splice(i, 1);
        }
    },

    // 更新
    updateLastSpeaker(state, user) {
        state.lastSpeaker = user
    },

    
    // 添加audio
    addAudio(state, user) {
        state.audioList.push(user)
    },

    // 删除audio
    removeAudio(state, user) {
        let i = -1;

        for (let index = 0; index < state.audioList.length; index++) {
            const userItem = state.audioList[index]

            if (userItem.userId === user.userId) {
                i = index;
                break;
            }
        }

        if (i > -1) {
            state.audioList.splice(i, 1);
        }
    },

    // 更新audio
    updateAudio(state, user) {
        for (let index = 0; index < state.audioList.length; index++) {
            const userItem = state.audioList[index]

            if (userItem.userId === user.userId) {
                for (const key in user) {
                    userItem[key] = user[key]
                }
                break;
            }
        }
    },

    // reset
    reset(state) {
        state.userList = []
        state.pageIndex = 1
        state.pageSize = 9

        state.totolUserNumber = 0
        state.audioList = []

        state.speakList = []
        state.lastSpeaker = null
    },

    // Disconnected reset
    disconnectedReset(state) {
        state.userList = []
        state.pageIndex = 1

        state.speakList = []
        state.lastSpeaker = null

        state.audioList = []
    }
}

// 计算属性
const getters = {
    getRealUserList(state) { // 真正的用户列表
        return state.userList.filter(i => !i.isTopShare)
    },

    getLockUser(state) { // 获取锁定的用户
        return state.userList.find(i => !i.isTopShare && i.isDbClick)
    },

    getTopShare(state) { // 获取topshare
        return state.userList.find(i => i.isTopShare)
    },

    // 获取指定id的用户信息
    getUser: (state) => (userId) => {
        let target = {}

        for (let index = 0; index < state.userList.length; index++) {
            const userItem = state.userList[index];
            if (!userItem.isTopShare && userItem.userId === userId) {
                target = userItem
                break
            }
        }

        return target
    },

    // 获取当前页的用户信息
    pageList: (state) => {
        const { userList, pageIndex, pageSize } = state

        const sortUserList = userList.map(item => {
            let weight = 0
            let videoWeight = 0
    
            const nowTime = new Date().getTime()
    
            let duration = nowTime - item.timeMs
    
            duration = duration > 0 ? duration : 0
    
            if (item.userId === Vue.prototype.$configs.peerId) { // 是自己
              weight += WEIGHT_CONFIG.SELF
            } else if (item.roleCode === ROLE_CODE.HOST) { // 是主持人
              weight += WEIGHT_CONFIG.HOST
            } else if (item.roleCode === ROLE_CODE.CO_HOST) { // 是联席主持人
              weight += WEIGHT_CONFIG.CO_HOST
            } else {
              weight += WEIGHT_CONFIG.USER
            }
    
            weight = (weight * MAX_CONF_TIME) + duration

            if (item.isUseShiPin) { // 正在开启视频
                videoWeight = 10 * MAX_CONF_TIME
            }
    
            return {
              ...item,
              userWeight: item.isTopShare ? 9999999999999 : weight + videoWeight
            }
        }).sort((a, b) => {
            const v1 = a.userWeight
            const v2 = b.userWeight
    
            return v2 - v1
          })

        
        // 按照每页个数和当前页数切分
        const startIndex =  (pageIndex - 1) * pageSize
        const arr = []
        for (let i = 0; i < pageSize; i++) {
            const item = sortUserList[startIndex + i]
            if (item) {
                arr.push(item)
            } else {
              break
            }
        }

        return arr
    },

    // 获取发言的用户
    getSpeakUser: (state) => {
        const len = state.speakList.length

        if (len > 0) {
            return state.speakList[len - 1]
        } else {
            return null
        }
    },


    // 当前userList结构的最大页数，最小为1
    maxPageIndex: (state) => {
        const { userList, pageSize } = state

        return Math.ceil(userList.length / pageSize) || 1
    },

    // // 当前会场中的人数
    // userNumber: (state) => {
    //     return state.userList.length
    // },

    // 是否有上一页
    isPrev: (state) => {
        return state.pageIndex !== 1
    },
    // 是否有下一页
    isNext: (state) => {
        const { userList, pageSize, pageIndex } = state

        const maxPageIndex = Math.ceil(userList.length / pageSize) || 1

        return pageIndex < maxPageIndex
    }
}

const actions = {
}

export default {
    namespaced: true,
    state,
    mutations,
    getters,
    actions
}
